import React from "react"
import styles from "./TextInput.module.scss"

const TextInput = ({ children, styleProps }) => (
  <h2 className={styles.TextInput} style={styleProps ? styleProps : null}>
    {children}
  </h2>
)

export default TextInput

import React, { useContext, useState } from "react"
import { Link } from "gatsby"

import { LangContext } from "../../../context/LangContext"

import CustomLink from "../../blocks/CustomLink/CustomLink"
import LangMenu from "../LangMenu/LangMenu"

import CloseBtn from "../../../images/close_btn.svg"
import HamburgerIcon from "../../../images/hamburger_icon.svg"

import styles from "./Navigation.module.scss"
import { useIsMobile } from "../../../hooks/useIsMobile"

const Navigation = ({
  elements,
  buttonSlug,
  logo,
  langActive,
  scrolled,
  alignLeft,
  hasLangSelection,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false)
  const { languages } = useContext(LangContext)
  const { isSmallDesktop } = useIsMobile()

  const toggle = () => {
    setIsOpen(!isOpen)
    document.body.classList.toggle("menu-open")
  }

  const toggleLangMenu = () => setIsLangMenuOpen(!isLangMenuOpen);

  const slugToHomePage = langActive !== 'en' ? `/${langActive}/` : '/'; 

  return (
    <header className={styles.HeaderStyle}>
      <CustomLink to={slugToHomePage}>
        <img
          className={
            scrolled ? `${styles.LogoIcon} ${styles.Scrolled}` : styles.LogoIcon
          }
          src={logo}
          alt={"logo"}
          style={isOpen ? { zIndex: "15" } : null}
        />
      </CustomLink>
      {!isSmallDesktop && (
        <nav
          className={
            alignLeft ? styles.NavigationStyleLeft : styles.NavigationStyle
          }
        >
          {elements &&
            elements.map((element, index) =>
              element.is_external_link ? (
                <CustomLink
                  key={index}
                  className={styles.NavigationLinks}
                  to={`${slugToHomePage}${element.slug}`}
                  onClick={toggle}
                >
                  {" "}
                  {element.name}
                </CustomLink>
              ) : (
                <a
                  key={index}
                  className={styles.NavigationLinks}
                  href={`#${element.slug}`}
                  onClick={toggle}
                >
                  {" "}
                  {element.name}
                </a>
              )
            )}
        </nav>
      )}
      <div className={styles.MobileMenuWrapper}>
        {languages && (
          <div className={styles.LangWrapper}>
            <button className={styles.LangButton} onClick={toggleLangMenu}>
              {langActive}
            </button>
            <LangMenu isOpen={isLangMenuOpen} slug={'blog'} />
          </div> 
        )}
        {buttonSlug && buttonSlug.slug === "#skontaktuj-sie" ? (
          <Link to={`/szkola/${buttonSlug.slug}`}>
            <button
              style={isOpen ? { zIndex: "15" } : null}
              className={styles.ButtonStyle}
            >
              {buttonSlug.name}
            </button>
          </Link>
        ) : (
          <Link to={`${slugToHomePage}/contact`}>
            <button
              style={isOpen ? { zIndex: "15" } : null}
              className={styles.ButtonStyle}
            >
              {buttonSlug && buttonSlug.name}
            </button>
          </Link>
        )}
        {isSmallDesktop && (
          <React.Fragment>
            <div onClick={toggle}>
              {!isOpen ? (
                <img
                  className={styles.HamburgerIcon}
                  src={HamburgerIcon}
                  alt="hamburger-icon"
                />
              ) : (
                <img
                  className={styles.HamburgerIcon}
                  src={CloseBtn}
                  style={isOpen ? { zIndex: "15" } : null}
                  alt="hamburger-icon"
                />
              )}
            </div>
            <nav>
              <div
                className={
                  isOpen
                    ? `${styles.NavbarCollapseShow} ${styles.NavbarCollapse}`
                    : styles.NavbarCollapse
                }
              >
                {elements &&
                  elements.map((element, index) =>
                    element.is_external_link ? (
                      <CustomLink
                        key={index}
                        className={styles.NavigationLinks}
                        to={`${slugToHomePage}${element.slug}`}
                        onClick={toggle}
                      >
                        {" "}
                        {element.name}
                      </CustomLink>
                    ) : (
                      <a
                        key={index}
                        className={styles.NavigationLinks}
                        href={`#${element.slug}`}
                        onClick={toggle}
                      >
                        {" "}
                        {element.name}
                      </a>
                    )
                  )}
              </div>
            </nav>
          </React.Fragment>
        )}
      </div>
    </header>
  )
}

export default Navigation

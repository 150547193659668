import { graphql, useStaticQuery } from "gatsby"

import {
  filterDataByLanguage,
  getGlobalMetaTags,
} from "./serviceHelpers"

export const useGlobalMetaTags = lang => {
  const { allKontentItemGlobalMetaTagsList } = useStaticQuery(
    graphql`
      query GlobalMetaTags {
        allKontentItemGlobalMetaTagsList {
          nodes {
            elements {
              meta_tags {
                linked_items {
                  elements {
                    content {
                      value
                    }
                    name {
                      value
                    }
                  }
                  id
                }
              }
            }
            preferred_language
          }
        }
      }
    `
  )

  const globalMetaTagsData = filterDataByLanguage(
    allKontentItemGlobalMetaTagsList,
    lang
  )

  return getGlobalMetaTags(globalMetaTagsData)
}

import React, { useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { useSiteLanguages } from "../../../hooks/useSiteLanguages"

import { LangContext } from "../../../context/LangContext"

const LocalizedLink = ({ activeClassName, children, className, to }) => {
  const { pageLang } = useContext(LangContext)
  const { getCleanedSlug } = useSiteLanguages()

  const url = to === '/' ? to : '/' + getCleanedSlug(pageLang, to)

  return (
    <Link
      activeClassName={activeClassName}
      className={className}
      to={url}
    >
      {children}
    </Link>
  )
}

export default LocalizedLink

LocalizedLink.propTypes = {
  activeClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
}

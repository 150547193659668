import React, { useEffect, useContext } from "react"
import { Link } from 'gatsby';

import { LANGUAGES } from "../../../utils/consts"

import { LangContext } from "../../../context/LangContext"

const CustomLink = ({ children, ...props }) => {
  const { pageLang, toggleLang } = useContext(LangContext)

  useEffect(() => {
    if (!window.location.href.includes('blog')) {
      toggleLang(pageLang)
    }
  }, [pageLang, toggleLang])

  const currentLang = props.to.includes('blog') ? LANGUAGES.EN : pageLang

  return (
    <Link
      {...props}
      language={currentLang}
    >
      { children }
    </Link>
  )
}

export default CustomLink

import { useCallback, useEffect, useState } from "react"

export const useWindowScroll = () => {
  const [isScrolledFromTop, setIsScrolledFromTop] = useState(false)

  const handleWindowScrollFromTop = useCallback(() => {
    if (window.scrollY === 0) {
      setIsScrolledFromTop(false)
      return
    }

    if (window.scrollY > 0 && !isScrolledFromTop) {
      setIsScrolledFromTop(true)
      return
    }
  }, [isScrolledFromTop])

  useEffect(() => {
    handleWindowScrollFromTop()
    window.addEventListener("scroll", handleWindowScrollFromTop)

    return () => window.removeEventListener("scroll", handleWindowScrollFromTop)
  }, [isScrolledFromTop, handleWindowScrollFromTop])

  return isScrolledFromTop
}

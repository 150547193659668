import { useEffect, useState } from "react"
import { debounce } from "lodash"

export const useIsMobile = () => {
  const [windowWidth, setWindowWidth] = useState(undefined)

  const handleWindowSizeChange = debounce(() => {
    setWindowWidth(window.innerWidth)
  }, 100)

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
      document.body.classList.remove("menu-open")
    }
  }, [handleWindowSizeChange])

  return {
    isMobile: windowWidth <= 650,
    isTablet: windowWidth <= 1044,
    isSmallDesktop: windowWidth <= 1120,
    isDesktop: windowWidth <= 1360,
  }
}

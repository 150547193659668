import get from "lodash/get"

export const getPictureUrl = data => {
  return get(data, "value[0].url", '')
}

export const getButtonData = data => {
  const pictureData = get(data, "linked_items[0].elements", [])

  return {
    name: get(pictureData, "button_text.value", ''),
    slug: get(pictureData, "slug_to_element.value", ''),
    buttonStyle: get(pictureData, "button_style.value[0].name", ''),
  }
}

export const getNavLinks = data => {
  const arrayOfNavlinks = [];
  const dataOfItems = get(data, "linked_items", []);
  dataOfItems.map(
    (item, i) =>
      (arrayOfNavlinks[i] = {
        name:  get(item, "elements.navigation_text.value", ''),
        slug:  get(item, "elements.navigation_slug.value", ''),
        is_external_link: !!get(item, 'elements.is_external_link.value[0].codename', '')
      })
  )

  return arrayOfNavlinks
}

export const getRichTextData = data => {

  return get(data, "resolvedData.html", '')
}

export const getTextData = data => {
  return get(data, "value", '')
}

export const getAssetData = data => {
  return get(data, "value[0].url", '')
}

export const getApplePersons = data => {
  const arrayOfApplePersons = []
  const dataOfItems = get(data, "linked_items", [])
  dataOfItems.map(
    (item, i) =>
      (arrayOfApplePersons[i] = {
        icon:  get(item, "elements.icon.value[0].url", ''),
        name:  get(item, "elements.apple_person_name.value", ''),
        position: get(item, "elements.apple_person_position.value", ''),
        threeText: get(item, "elements.three_text.resolvedData.html", ''),
        percent: get(item, "elements.percent.value", ''),
      })
  )

  return arrayOfApplePersons
}
export const getAssets = data => {
  const arrayOfAssets = []
  const dataOfItems = get(data, "value", [])
  dataOfItems.map(
    (item, i) =>
      (arrayOfAssets[i] = get(item, "url", ''))
  )

  return arrayOfAssets
}

export const getVideos = data => {
  const arrayOfVideos = []
  const dataOfItems = get(data, "linked_items", [])
  dataOfItems.map(
    (item, i) =>
      (arrayOfVideos[i] = {
        videoUrl:  get(item, "elements.video_url.value", ''),
        header:  get(item, "elements.video_header.value", ''),
        description:  get(item, "elements.video_description.value", ''),
      })
  )

  return arrayOfVideos
}
export const getFaq = data => {
  const arrayOfFaq = []
  const dataOfItems = get(data, "linked_items", [])
  dataOfItems.map(
    (item, i) =>
      (arrayOfFaq[i] = {
        question:  get(item, "elements.faq_question.value", ''),
        answer:  get(item, "elements.faq_answer_rich_text.resolvedData.html", ''),
      })
  )

  return arrayOfFaq
}

export const getPerson = data => {
  const arrayOfPersons = [];
  const dataOfItems = get(data, "linked_items", [])
  dataOfItems.map(
    (item, i) =>
      (arrayOfPersons[i] = {
        icon: get(item, "elements.person_picture.value[0].url", ''),
        name: get(item, "elements.name_and_surname.value", ''),
        position: get(item, "elements.person_position.value", ''),
        company: get(item, "elements.company.value", ''),
      })
  )

  return arrayOfPersons[0]
}

export const filterDataByLanguage = (data, language, index = 0) => {
 const securedData = get(data, "nodes", [])
 const filtredData = securedData.filter(
    collection => collection.preferred_language === language
  )[index];
    return  get(filtredData, "elements", '')
}

export const getCirclesData = data => {
  const arrayOfCircles = []
  data.linked_items.map(
    (item, i) => (arrayOfCircles[i] = get(item, "elements.circle_text.value", ''))
  )

  return arrayOfCircles
}

export const getCompanySizeData = data => {
  const arrayOfCompanySizes = [];
  const dataOfItems = get(data, "linked_items", [])
  dataOfItems.map(
    (item, i) =>
      (arrayOfCompanySizes[i] = get(item, "elements.company_size_text.value", ''))
  )

  return arrayOfCompanySizes
}

export const getFeaturesData = data => {
  const arrayOfFeatures = []
  const dataOfItems = get(data, "linked_items", [])
  dataOfItems.map(
    (item, i) =>
      (arrayOfFeatures[i] = {
        featureIcon: get(item, "elements.feature_logo.value[0].url", ''),
        featureTitle:   get(item, "elements.feature_title.value", ''),
        featureDescription:  get(item, "elements.feature_description.value", ''),
      })
  )
  return arrayOfFeatures
}

export const getCountedNumbers = data => {
  const arrayOfNumbers = [];
  const dataOfItems = get(data, "linked_items", []);
  dataOfItems.map(
    (item, i) => (arrayOfNumbers[i] = get(item, "elements.counted_text.value", ''))
  )

  return arrayOfNumbers
}

export const getProperty = data => {
  const arrayOfProperties = [];
  const dataOfItems = get(data, "linked_items", []);
  dataOfItems.map(
    (item, i) => (arrayOfProperties[i] = get(item, "elements.property_text.value", ''))
  )

  return arrayOfProperties
}

export const getRecomendationData = data => {
  const arrayOfRecomendations = []
  data.linked_items.map((item, i) => {
    const personData = getPerson(item.elements.recomendation_person)
    const emptyPerson = {
      icon: "",
      name: "",
      position: "",
      company: "",
    }
    let personItem = personData !== undefined ? personData : emptyPerson

    const referenceItem = {
      heading: getTextData(get(item, "elements.recomendation_text", '')),
      quote: getTextData(get(item, "elements.recomendation_quote", '')),
      authorAvatarUrl: get(personItem, "icon", ''),
      authorName: get(personItem, "name", ''),
      authorPosition: get(personItem, "position", ''),
      authorOrganizationLogoUrl: getPictureUrl(
        get(item, "elements.recomendation_company_logo", '')
      ),
      pictureUrl: getPictureUrl( get(item, "elements.recomendation_asset", '')),
    }
    return (arrayOfRecomendations[i] = referenceItem)
  })

  return arrayOfRecomendations
}


export const getLanguages = data => {
    const arrayOfLanguage = [];
    const dataOfItems = get(data, "nodes", [{"preferred_language": "en"}]);
    dataOfItems.map(
      (item, i) => (arrayOfLanguage[i] = get(item, "preferred_language", ''))
    )

    return arrayOfLanguage
  }

export const getDownloadItemItems = data => {
  const arrayOfDownloadItems = [];
  const dataOfItems = get(data, "linked_items", []);
  dataOfItems.map(
    (item, i) => {
      const referenceItem = {
        date: getTextData(get(item.elements, "date", "")),
        description: getTextData(get(item.elements, "description", "")),
        button: getButtonData(get(item.elements, "download_button", "")),
        icon: getAssetData(get(item.elements, "icon", "")),
        fileUrl: getAssetData(get(item.elements, "download_file", ''))
      }
      return (arrayOfDownloadItems[i] = referenceItem)
    })
    return arrayOfDownloadItems
}
export const getJobItems = data => {
  const arrayOfDownloadItems = [];
  const dataOfItems = get(data, "linked_items", [])
  dataOfItems.map(
    (item, i) => {
      const referenceItem = {
        description: getTextData(get(item.elements, "description", "")),
        icon: getAssetData(get(item.elements, "icon", "")),
      }
      return (arrayOfDownloadItems[i] = referenceItem)
    })
    return arrayOfDownloadItems
}
export const getMetaDescription = data => {
  const items = get(data, "linked_items[0].elements", {});
  const metaObject = {
    metaContent: getTextData(items.metacontent),
    metaDescription: getTextData(items.metadescription),
    metaImage: getAssetData(items.metaimg),
    metaTitle: getTextData(items.metatitle),
  }
  return metaObject;
}

export const getGlobalMetaTags = data =>
  get(data, 'meta_tags.linked_items', []).map(tag => ({
    name: get(tag, 'elements.name.value', ''),
    content: get(tag, 'elements.content.value', ''),
    id: get(tag, 'id', '')
  }))

// heading: 'Of the seven markets where Unilever operates, several hundred managers had to attend additional training, disconnected from their day-to-day assignments. Team performance and employee engagement regarding long-term training averaged 20%. After implementing Grow Uperion’s platform and methodology, training had been accomplished by over 85% of employees in a given time while acquiring knowledge, and even having fun at the same time.',
// quote: "Grow Uperion is a very interesting concept mainly due to the platform's self-service. Engaging mechanics are so effective that employees themselves use the platform and achieve their goals, motivating themselves to do so even within broader structures - teams, countries and regions.",
// authorAvatarUrl: 'https://cms-assets.tenderhut.com/b005f9bb-773e-01a0-f69a-2ecf8a5d5b2f/assets/62d680e5-2349-41a5-bfbf-b4d2570e6fe8/unilever_person.png',
// authorName: 'Maciej Lasoń',
// authorPosition: 'Head Of Communications East Europe, Unilever ',
// authorOrganizationLogoUrl: 'https://cms-assets.tenderhut.com/b005f9bb-773e-01a0-f69a-2ecf8a5d5b2f/assets/7a842901-04be-4db6-ada6-0b2088dad662/Unilogo.svg',
// pictureUrl: 'https://cms-assets.tenderhut.com/b005f9bb-773e-01a0-f69a-2ecf8a5d5b2f/assets/e7716652-3e8b-42ea-89ac-7ddf335a119e/wizualizacja2.png'
// }

import React, { useContext } from "react"
import PropTypes from "prop-types"

import { LangContext } from "../../../context/LangContext"

import { useSiteLanguages } from "../../../hooks/useSiteLanguages"

import styles from "./LangMenu.module.scss"

const LangMenu = ({ isOpen, slug = '' }) => {
  const { languages, pageLang } = useContext(LangContext)
  const { redirectOnLangChange } = useSiteLanguages()

  const handleToggleLang = lang => {
    redirectOnLangChange(lang, slug)
  }

  const activeClass = isOpen ? styles.Active : ""

  return (
    <ul className={`${styles.Container} ${activeClass}`}>
      {languages.map(lang => lang !== pageLang && (
        <li key={lang} className={styles.LangItem}>
          <button
            className={styles.LangButton}
            onClick={() => handleToggleLang(lang)}
          >
            {lang}
          </button>
        </li>
      ))}
    </ul>
  )
}

export default LangMenu

LangMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  slug: PropTypes.string,
}

import { useContext, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { LangContext } from "../context/LangContext"

import { LANGUAGES } from "../utils/consts"

const languages = Object.values(LANGUAGES).map(lang => lang.toLowerCase())

export const useSiteLanguages = () => {
  const [languagesMap, setLanguagesMap] = useState([])

  const { toggleLang } = useContext(LangContext)
  const { allKontentItemPage } = useStaticQuery(graphql`
    query {
      allKontentItemPage {
        nodes {
          system {
            id
            language
            codename
          }
          preferred_language
          elements {
            page_url {
              value
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    const languagesMap = allKontentItemPage.nodes.reduce(
      (languageMap, node) => {
        if (checkIfPageWillBeCreated(node)) {
          return [
            ...languageMap,
            {
              id: node.system.id,
              pageUrl: node.elements.page_url.value,
              lang: node.system.language,
            },
          ]
        }
        return languageMap
      },
      []
    )

    setLanguagesMap(languagesMap)
  }, [allKontentItemPage.nodes])

  const checkIfPageWillBeCreated = node => {
    return node.preferred_language === node.system.language
  }

  const checkIfPathnameContainsLang = value => languages.includes(value)

  const stripLangFromPathname = pathname => {
    const splittedPathname = decodeURI(pathname).split("/")
    if (checkIfPathnameContainsLang(splittedPathname[1])) {
      splittedPathname.splice(1, 1)
    }
    return `${splittedPathname.join("/")}`
  }

  const getLangFromPathname = () => {
    const pathname = decodeURI(typeof window !== "undefined" ? window.location.pathname : '')
    var lang = pathname.split("/")[1]
    const languages = Object.values(LANGUAGES).map(lang =>
      lang.toLowerCase()
    )
    return languages.includes(lang) ? lang : 'en'
  }

  const getNewPathname = lang => {
    const foundPathname = languagesMap.find(
      item => item.pageUrl === stripLangFromPathname(window.location.pathname)
    )

    if (!foundPathname) {
      toggleLang(lang)
      return
    }

    const newPathname = languagesMap.find(
      item => item.id === foundPathname.id && item.lang === lang
    )

    if (!newPathname) {
      return foundPathname
    }

    toggleLang(lang)

    return newPathname
  }

  const cleanSlug = slug => slug.replace(/\//g, "").trim()

  const getCleanedSlug = (lang, slug) => {
    let cleanedSLug = cleanSlug(slug)
    return lang === LANGUAGES.EN ? cleanedSLug : `${lang}/${cleanedSLug}`
  }

  const redirectOnLangChange = (lang, slug = '') => {
    const newPathname = getNewPathname(lang)

    if (!newPathname) {
      window.location.pathname = `${lang === LANGUAGES.EN ? `/` : `${lang}/`}${slug}`
      return
    }

    window.location.pathname =
      `${
        newPathname.lang === LANGUAGES.EN
          ? `${newPathname.pageUrl}`
          : `${newPathname.lang}${newPathname.pageUrl}`
      }`
  }

  return {
    languagesMap,
    stripLangFromPathname,
    redirectOnLangChange,
    getLangFromPathname,
    getCleanedSlug,
  }
}

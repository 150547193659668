import React from "react"
import styles from "./Header.module.scss"

const Header = ({ children, styleProps }) => (
  <h1
    dangerouslySetInnerHTML={{ __html: children }}
    className={styles.Header}
    style={styleProps ? styleProps : null}
  />
)

export default Header
